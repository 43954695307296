<template>
  <div class="be-discovered">
    <h1 class="title">We will unlock your full potential. Submit an application and start your career with us.</h1>

    <!-- Gender selection -->
    <div class="gender-selection">
      <label class="radio-label" :class="{ selected: gender === 'female' }">
        <input type="radio" value="female" v-model="gender" /> Female
      </label>
      <label class="radio-label" :class="{ selected: gender === 'male' }">
        <input type="radio" value="male" v-model="gender" /> Male
      </label>
      <label class="radio-label" :class="{ selected: gender === 'other' }">
        <input type="radio" value="other" v-model="gender" /> Other
      </label>
    </div>

    <!-- Main form -->
    <form @submit.prevent="submitApplication" class="application-form">
      <input type="text" v-model="fullName" placeholder="Full Name*" />
      <input type="date" v-model="birth" placeholder="Date of Birth*" />

      <!-- Country selection -->
      <div class="country-section">
        <select v-model="selectedCountry" class="country-select">
          <option value="" disabled>Select Country*</option>
          <option
            v-for="country in countries"
            :key="country.code"
            :value="country.name"
          >
            {{ country.name }}
          </option>
          <option value="other">Other</option>
        </select>

        <input
          v-if="selectedCountry === 'other'"
          type="text"
          v-model="customCountry"
          placeholder="Enter Country"
          class="other-country-input"
        />
      </div>

      <!-- City -->
      <input type="text" v-model="city" placeholder="City*" />

      <!-- Email -->
      <input type="email" v-model="email" placeholder="Email*" />

      <!-- Measurements (number fields) -->
      <input type="number" v-model="height" placeholder="Height (cm)*" />
      <input type="number" v-model="bust" placeholder="Bust (cm)*" />
      <input type="number" v-model="waist" placeholder="Waist (cm)*" />
      <input type="number" v-model="hips" placeholder="Hips (cm)*" />

      <!-- Phone -->
      <div class="phone-section">
        <select v-model="selectedCountryForPhone" @change="updatePhoneCode">
          <option v-for="country in countries" :key="country.code" :value="country">
            {{ country.name }} ({{ country.phoneCode }})
          </option>
        </select>
        <input type="tel" v-model="phone" :placeholder="'Phone ' + selectedPhoneCode" />
      </div>

      <!-- Instagram (optional) -->
      <input type="text" v-model="instagram" placeholder="Instagram (Optional)" />

      <!-- Photo examples -->
      <div class="photo-examples">
        <div class="photo-example" @click="triggerFileUpload">
          <img src="@/assets/examples/full-body.jpg" alt="Full Body" />
          <p>Full Body</p>
        </div>
        <div class="photo-example" @click="triggerFileUpload">
          <img src="@/assets/examples/waist-up.jpg" alt="Waist Up" />
          <p>Waist Up</p>
        </div>
        <div class="photo-example" @click="triggerFileUpload">
          <img src="@/assets/examples/close-up.jpg" alt="Close-Up" />
          <p>Close-Up</p>
        </div>
        <div class="photo-example" @click="triggerFileUpload">
          <img src="@/assets/examples/profile.jpg" alt="Profile" />
          <p>Profile</p>
        </div>
      </div>

      <!-- File input (hidden) -->
      <input
        type="file"
        multiple
        @change="handleFileUpload"
        ref="fileInput"
        style="display: none;"
      />

      <!-- Consent checkbox -->
      <div class="consent">
        <label>
          <input type="checkbox" v-model="acceptTerms" />
          I accept the terms and conditions of using this site and agree to the processing of my personal data.
        </label>
      </div>

      <!-- Submit button -->
      <button type="submit" :disabled="!validForm || !acceptTerms">Submit</button>
    </form>
  </div>
</template>

<script>
export default {
  name: "BeDiscoveredPage",
  data() {
    return {
      fullName: "",
      birth: "",
      email: "",
      phone: "",
      instagram: "",
      gender: "",
      height: "",
      bust: "",
      waist: "",
      hips: "",
      city: "",
      selectedCountry: "",
      customCountry: "",
      selectedCountryForPhone: null,
      photos: [],
      acceptTerms: false, // Для отслеживания состояния чекбокса
      countries: [
        { name: "Russia", code: "RU", phoneCode: "+7" },
        { name: "United States", code: "US", phoneCode: "+1" },
        { name: "United Kingdom", code: "GB", phoneCode: "+44" },
        { name: "Germany", code: "DE", phoneCode: "+49" },
        { name: "France", code: "FR", phoneCode: "+33" },
        { name: "Spain", code: "ES", phoneCode: "+34" },
        { name: "Italy", code: "IT", phoneCode: "+39" },
        { name: "Turkey", code: "TR", phoneCode: "+90" },
        { name: "Japan", code: "JP", phoneCode: "+81" },
      ],
    };
  },
  computed: {
    validForm() {
      const hasBasicFields =
        this.fullName &&
        this.birth &&
        this.city &&
        this.email &&
        this.phone &&
        this.height &&
        this.bust &&
        this.waist &&
        this.hips &&
        this.gender;

      const countryIsValid =
        this.selectedCountry === "other"
          ? this.customCountry.trim() !== ""
          : this.selectedCountry !== "";

      const hasPhotos = this.photos.length > 0;

      return hasBasicFields && countryIsValid && hasPhotos;
    },
    selectedPhoneCode() {
      return this.selectedCountryForPhone
        ? this.selectedCountryForPhone.phoneCode
        : "";
    },
  },
  methods: {
    handleFileUpload(event) {
      const files = Array.from(event.target.files);
      this.photos = files.filter((file) => file.type.startsWith("image/"));
      if (files.length !== this.photos.length) {
        alert("Only image files are allowed.");
      }
    },
    submitApplication() {
      const finalCountry =
        this.selectedCountry === "other" ? this.customCountry : this.selectedCountry;

      const subject = `Application from: ${this.fullName}`;
      const bodyLines = [
        `Full Name: ${this.fullName}`,
        `Birth: ${this.birth}`,
        `Gender: ${this.gender}`,
        `Country: ${finalCountry}`,
        `City: ${this.city}`,
        `Email: ${this.email}`,
        `Phone: ${this.phone}`,
        `Instagram: ${this.instagram || "N/A"}`,
        `Height (cm): ${this.height}`,
        `Bust (cm): ${this.bust}`,
        `Waist (cm): ${this.waist}`,
        `Hips (cm): ${this.hips}`,
        this.photos.length
          ? `Photos attached (filenames): ${this.photos.map((f) => f.name).join(", ")}`
          : `No photos`,
      ];

      const body = bodyLines.join("\n");
      const mailToLink = `mailto:info@futurefacemodels.com?subject=${encodeURIComponent(
        subject
      )}&body=${encodeURIComponent(body)}`;

      window.location.href = mailToLink;
    },
    triggerFileUpload() {
      this.$refs.fileInput.click();
    },
    updatePhoneCode() {
      if (this.selectedCountryForPhone) {
        this.phone = this.selectedCountryForPhone.phoneCode;
      }
    },
  },
};
</script>

<style scoped>
.be-discovered {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Helvetica Neue', Arial, sans-serif;
}

.title {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  font-weight: 300;
  font-size: 28px;
  text-align: center;
  letter-spacing: 1px;
  margin-bottom: 20px;
  color: #070707;
}

/* Consent section */
.consent {
  margin: 20px 0;
  font-size: 14px;
  color: #555;
  text-align: left;
}

.consent label {
  display: flex;
  align-items: center;
  gap: 10px;
}

.consent input[type="checkbox"] {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

/* Gender selection */
.gender-selection {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.radio-label {
  cursor: pointer;
  padding: 10px 20px;
  font-size: 18px;
  border-radius: 20px;
  transition: background-color 0.3s, transform 0.2s;
  border: 1px solid #ccc;
}

.radio-label.selected {
  background-color: #070707;
  color: white;
  transform: scale(1.1);
}

.radio-label input {
  display: none;
}

/* Application form */
.application-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 30px;
}

/* Тонкие линии и крупный шрифт (18px) */
.application-form input[type='text'],
.application-form input[type='email'],
.application-form input[type='date'],
.application-form input[type='tel'],
.application-form input[type='number'],
.country-select {
  border: none;
  border-bottom: 1px solid #333; /* Линия тоньше */
  padding: 10px;
  font-size: 18px; /* Крупнее шрифт */
  outline: none;
  transition: border-color 0.3s;
  background-color: transparent;
}

.application-form input:focus,
.country-select:focus {
  border-bottom-color: #666;
}

/* Country section (select + optional input) */
.country-section {
  position: relative;
}

.country-section::after {
  content: '▼';
  font-size: 12px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: #333;
}

/* Для ручного ввода 'other' страны */
.other-country-input {
  border: none;
  border-bottom: 1px solid #333;
  padding: 10px;
  outline: none;
  font-size: 18px;
  margin-top: 10px;
}

/* Phone section */
.phone-section {
  display: flex;
  gap: 10px;
}

.photo-examples {
  display: flex;
  justify-content: center;
  gap: 0;
  margin: 0;
}

.photo-example {
  text-align: center;
  flex: 1;
  cursor: pointer;
  margin: 0;
}

.photo-example img {
  width: 180px;
  height: auto;
  border-radius: 10px;
}

.photo-example p {
  margin-top: 10px;
  font-size: 14px;
  color: #555;
}

/* Button */
button {
  padding: 20px;
  background-color: black;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #444;
}

button:disabled {
  background-color: grey;
  cursor: not-allowed;
}

/* Responsive */
@media (max-width: 768px) {
  .application-form input[type='text'],
  .application-form input[type='email'],
  .application-form input[type='date'],
  .application-form input[type='tel'],
  .application-form input[type='number'],
  .application-form select {
    font-size: 16px;
    padding: 8px;
  }
  .title {
    margin-top: 40px; /* или другое значение, чтобы опустить ещё ниже */
  }
  button {
    font-size: 16px;
    padding: 12px;
  }

  .photo-examples {
    flex-wrap: wrap;
    gap: 1px;
    justify-content: center;
    align-items: center;
  }

  .photo-example img {
    width: 80px;
  }

  .photo-example p {
    font-size: 12px;
  }
  
}
@media (max-width: 480px) {
  .title {
    margin-top: 40px; /* для ещё более узких экранов можно увеличить отступ */
  }
}
</style>
