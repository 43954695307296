<template>
  <div class="models-page">
    <!-- Заголовок с классом .minimal-heading -->
    <h1 class="minimal-heading">Models</h1>
    <div class="models-list">
      <ModelCard
        v-for="model in filteredAndSortedModels"
        :key="model.id"
        :model="model"
        class="model-card"
      />
    </div>
  </div>
</template>

<script>
import ModelCard from '@/components/ModelCard.vue';
import { models } from '@/data/modelsData.js';

export default {
  name: 'ModelsPage',
  components: {
    ModelCard,
  },
  data() {
    return {
      models: models || [], // Подстраховка, если models вдруг не определён
    };
  },
  computed: {
    filteredAndSortedModels() {
      // Считываем searchQuery из роутера
      const searchQuery = (this.$route.query.search || '').toLowerCase();

      // Безопасная фильтрация: (model.name || '') защитит от undefined
      const filtered = this.models.filter((model) => {
        const name = (model.name || '').toLowerCase();
        return name.includes(searchQuery);
      });

      // Сортировка по имени, аналогично защищаемся от undefined
      return filtered.sort((a, b) => {
        const nameA = (a.name || '');
        const nameB = (b.name || '');
        return nameA.localeCompare(nameB);
      });
    },
  },
  mounted() {
    // Лёгкая задержка, чтобы сработала CSS-анимация
    setTimeout(() => {
      this.$el.classList.add('loaded');
    }, 200);
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&display=swap');

/* Основные стили для страницы моделей */
.models-page {
  text-align: center;
  padding: 20px;
  /* Шрифт страницы - Playfair Display */
  font-family: 'Playfair Display', serif;
  transform: translateY(50px);
  opacity: 0;
  transition: transform 1s ease, opacity 1s ease;
}

/* Класс loaded активирует плавное появление */
.models-page.loaded {
  transform: translateY(0);
  opacity: 1;
}

/* УБРАН глобальный стиль h1 (48px), чтобы не конфликтовал с .minimal-heading */

/* Стили списка моделей */
.models-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

/* Стиль заголовка «Models» по классу .minimal-heading */
.minimal-heading {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  font-weight: 300;
  font-size: 36px;
  letter-spacing: 1px;
  color: #333;
  text-transform: uppercase;
  margin-bottom: 20px;
}
@media (max-width: 768px) {
  .model-card {
    width: 85vw;  /* Занимает почти всю ширину экрана на мобильных */
    height: auto; /* Высота подстраивается, чтобы фото не искажалось */
    margin: 10px auto; 
    cursor: pointer; /* Как и раньше, если хотите оставить «указатель» */
  }

  .image-wrapper {
    height: auto; /* Позволяем контенту самоподстраиваться */
  }

  .model-card img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 10px;
  }

  .model-name {
    font-size: 20px; /* Увеличиваем шрифт названия для мобильных */
  }
}

</style>
