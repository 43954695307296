<template>
  <!-- Если у модели есть id, используем router-link -->
  <router-link
    v-if="model.id"
    class="model-card"
    :to="`/models/${model.id}`"
    role="link"
    tabindex="0"
  >
    <!-- Изображение модели с анимацией при загрузке -->
    <div class="image-wrapper">
      <img
        :src="imageSrc"
        :alt="model.name || 'Model photo'" 
        loading="lazy"
        @load="imageLoaded = true"
        @error="onImageError"
        :class="{ 'image-loaded': imageLoaded }"
      />
    </div>
    <!-- Имя модели -->
    <h2 class="model-name">{{ model.name || 'Unknown' }}</h2>
  </router-link>

  <!-- Если нет id, просто выводим заглушку или ошибку -->
  <div v-else class="model-card model-card__invalid">
    <div class="image-wrapper">
      <img
        :src="fallbackImage"
        alt="No model data"
        class="image-loaded"
      />
    </div>
    <h2 class="model-name">No Model Data</h2>
  </div>
</template>

<script>
export default {
  name: "ModelCard",
  props: {
    model: {
      type: Object,
      required: true,
    },
    // fallbackImage на случай, если основная картинка не загрузится
    fallbackImage: {
      type: String,
      default: "/assets/fallback.png",
    },
  },
  data() {
    return {
      imageLoaded: false,
      imageError: false,
    };
  },
  computed: {
    // Если изображение дало ошибку, подставляем fallback
    imageSrc() {
      if (this.imageError || !this.model.mainPhoto) {
        return this.fallbackImage;
      }
      return this.model.mainPhoto;
    },
  },
  methods: {
    onImageError() {
      this.imageError = true;
    },
  },
};
</script>

<style scoped>
/* Карточка модели */
.model-card {
  width: 200px;
  height: 300px;
  cursor: pointer;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-decoration: none; /* router-link по сути <a>, убираем подчеркивание */
  color: inherit;        /* Сохраняем цвет текста при переходе */
}

.model-card:hover {
  transform: scale(1.05);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}

/* Карточка при отсутствии id (просто стилизуем так же) */
.model-card__invalid {
  opacity: 0.7;
  cursor: not-allowed;
}

/* Обёртка для изображения модели */
.image-wrapper {
  width: 100%;
  height: 80%;
  overflow: hidden;
  position: relative;
}

/* Изображение модели с плавной анимацией */
.model-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  opacity: 0; /* Начальная прозрачность изображения */
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  transform: scale(1.1); /* Начальное увеличение для плавного приближения */
}

/* Когда изображение загружено, меняется прозрачность и масштаб */
.model-card img.image-loaded {
  opacity: 1;
  transform: scale(1);
}

.model-card img:hover {
  opacity: 0.8;
}

/* Имя модели */
.model-name {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  font-weight: 300;
  font-size: 18px;
  color: #333;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-top: 10px;
  transition: color 0.3s ease;
}

.model-name:hover {
  color: grey;
}
@media (max-width: 768px) {
  /* Уменьшим ширину карточки чуть-чуть */
  .model-card {
    width: 70vw;  /* вместо 90vw */
    height: auto; 
    margin: 8px auto; /* вместо 10px, убавляем отступ */
  }

  /* Контейнер под изображение */
  .image-wrapper {
    height: auto;
  }

  /* Само изображение */
  .model-card img {
    height: auto;
    width: 100%;
    border-radius: 6px; /* Можно тоже чуть уменьшить до 6px, если нужно */
  }

  /* Название модели — чуть меньше шрифт */
  .model-name {
    font-size: 18px; /* вместо 20px */
    margin-top: 8px; /* вместо 10px */
  }
}

</style>
