export const models = [
    {
      id: 1,
      name: "Alexandra",
      height: "175 cm",
      bust: "80 cm",
      waist: "60 cm",
      hips: "89 cm",
      shoes: "39",
      hair: "Brown",
      eyes: "Gray-Green",
      mainPhoto: require("@/assets/models/Alexandra/alexandra_1.jpg"),
      photos: [
        require('@/assets/models/Alexandra/alexandra_1.jpg'),
        require('@/assets/models/Alexandra/alexandra_2.jpg'),
        require('@/assets/models/Alexandra/alexandra_3.jpg'),
        require('@/assets/models/Alexandra/alexandra_4.jpg'),
        require('@/assets/models/Alexandra/alexandra_5.jpg'),
        require('@/assets/models/Alexandra/alexandra_6.jpg'),
        require('@/assets/models/Alexandra/alexandra_7.jpg'),
        require('@/assets/models/Alexandra/alexandra_8.jpg'),
        require('@/assets/models/Alexandra/alexandra_9.jpg'),
        require('@/assets/models/Alexandra/alexandra_10.jpg'),
        require('@/assets/models/Alexandra/alexandra_11.jpg'),
        require('@/assets/models/Alexandra/alexandra_12.jpg'),
        require('@/assets/models/Alexandra/alexandra_13.jpg'),
        require('@/assets/models/Alexandra/alexandra_14.jpg'),
        require('@/assets/models/Alexandra/alexandra_15.jpg'),
        require('@/assets/models/Alexandra/alexandra_16.jpg'),
        require('@/assets/models/Alexandra/alexandra_17.jpg'),
        require('@/assets/models/Alexandra/alexandra_18.jpg'),
        require('@/assets/models/Alexandra/alexandra_19.jpg'),
        require('@/assets/models/Alexandra/alexandra_20.jpg'),
        require('@/assets/models/Alexandra/alexandra_21.jpg'),
        require('@/assets/models/Alexandra/alexandra_22.jpg'),
        require('@/assets/models/Alexandra/alexandra_23.jpg'),

      ],
    },
    {
      id: 2,
      name:"Julia",
      height: "172 cm",
      bust: "78 cm",
      waist: "58 cm",
      hips: "87 cm",
      shoes: "39",
      hair: "Brown",
      eyes: "Blue",
      mainPhoto: require("@/assets/models/Anastasia/anastasia_1.jpg"),
      photos: [
        require('@/assets/models/Anastasia/anastasia_1.jpg'),
        require('@/assets/models/Anastasia/anastasia_2.jpg'),
        require('@/assets/models/Anastasia/anastasia_3.jpg'),
        require('@/assets/models/Anastasia/anastasia_4.jpg'),
        require('@/assets/models/Anastasia/anastasia_5.jpg'),
        require('@/assets/models/Anastasia/anastasia_6.jpg'),
        require('@/assets/models/Anastasia/anastasia_7.jpg'),
        require('@/assets/models/Anastasia/anastasia_8.jpg'),
        require('@/assets/models/Anastasia/anastasia_9.jpg'),
        require('@/assets/models/Anastasia/anastasia_10.jpg'),
        require('@/assets/models/Anastasia/anastasia_11.jpg'),
        require('@/assets/models/Anastasia/anastasia_12.jpg'),
        require('@/assets/models/Anastasia/anastasia_13.jpg'),
        require('@/assets/models/Anastasia/anastasia_14.jpg'),
      ],
    },
    {
        id: 3,
        name: "Anna",
        height: "170 cm",
        bust: "85 cm",
        waist: "60 cm",
        hips: "89 cm",
        shoes: "38.5",
        hair: "Blonde",
        eyes: "Blue",
        mainPhoto: require("@/assets/models/Anna/anna_31.jpg"),
        photos: [
            require("@/assets/models/Anna/anna_1.jpg"),
            require("@/assets/models/Anna/anna_2.jpg"),
            require("@/assets/models/Anna/anna_3.jpg"),
            require("@/assets/models/Anna/anna_4.jpg"),
            require("@/assets/models/Anna/anna_5.jpg"),
            require("@/assets/models/Anna/anna_6.jpg"),
            require("@/assets/models/Anna/anna_7.jpg"),
            require("@/assets/models/Anna/anna_8.jpg"),
            require("@/assets/models/Anna/anna_9.jpg"),
            require("@/assets/models/Anna/anna_10.jpg"),
            require("@/assets/models/Anna/anna_11.jpg"),
            require("@/assets/models/Anna/anna_12.jpg"),
            require("@/assets/models/Anna/anna_13.jpg"),
            require("@/assets/models/Anna/anna_14.jpg"),
            require("@/assets/models/Anna/anna_15.jpg"),
            require("@/assets/models/Anna/anna_16.jpg"),
            require("@/assets/models/Anna/anna_17.jpg"),
            require("@/assets/models/Anna/anna_18.jpg"),
            require("@/assets/models/Anna/anna_19.jpg"),
            require("@/assets/models/Anna/anna_20.jpg"),
            require("@/assets/models/Anna/anna_21.jpg"),
            require("@/assets/models/Anna/anna_22.jpg"),
            require("@/assets/models/Anna/anna_23.jpg"),
            require("@/assets/models/Anna/anna_24.jpg"),
            require("@/assets/models/Anna/anna_25.jpg"),
            require("@/assets/models/Anna/anna_26.jpg"),
            require("@/assets/models/Anna/anna_27.jpg"),
            require("@/assets/models/Anna/anna_28.jpg"),
            require("@/assets/models/Anna/anna_29.jpg"),
            require("@/assets/models/Anna/anna_30.jpg"),
        ],
      },
      {
          id: 4,
         name: "Dasha",
         height: "177 cm",
         bust: "82 cm",
         waist: "60 cm",
         hips: "88 cm",
         shoes: "39",
         hair: "Brown",
         eyes: "Brown",
         mainPhoto: require("@/assets/models/Dasha/dasha_1.jpg"),
         photos: [
        require("@/assets/models/Dasha/dasha_1.jpg"),
        require("@/assets/models/Dasha/dasha_2.jpg"),
        require("@/assets/models/Dasha/dasha_3.jpg"),
        require("@/assets/models/Dasha/dasha_4.jpg"),
        require("@/assets/models/Dasha/dasha_5.jpg"),
        require("@/assets/models/Dasha/dasha_6.jpg"),
        require("@/assets/models/Dasha/dasha_7.jpg"),
        require("@/assets/models/Dasha/dasha_8.jpg"),
        require("@/assets/models/Dasha/dasha_9.jpg"),
        require("@/assets/models/Dasha/dasha_10.jpg"),
        require("@/assets/models/Dasha/dasha_11.jpg"),
        require("@/assets/models/Dasha/dasha_12.jpg"),
        require("@/assets/models/Dasha/dasha_13.jpg"),
        require("@/assets/models/Dasha/dasha_14.jpg"),
        require("@/assets/models/Dasha/dasha_15.jpg"),
        require("@/assets/models/Dasha/dasha_16.jpg"),
        require("@/assets/models/Dasha/dasha_17.jpg"),
        require("@/assets/models/Dasha/dasha_18.jpg"),
        require("@/assets/models/Dasha/dasha_19.jpg"),
        require("@/assets/models/Dasha/dasha_20.jpg"),
        require("@/assets/models/Dasha/dasha_21.jpg"),
        require("@/assets/models/Dasha/dasha_22.jpg"),
        require("@/assets/models/Dasha/dasha_23.jpg"),
        require("@/assets/models/Dasha/dasha_24.jpg"),
        require("@/assets/models/Dasha/dasha_25.jpg"),
        require("@/assets/models/Dasha/dasha_26.jpg"),
        require("@/assets/models/Dasha/dasha_27.jpg"),
        require("@/assets/models/Dasha/dasha_28.jpg"),
        require("@/assets/models/Dasha/dasha_29.jpg"),
        require("@/assets/models/Dasha/dasha_30.jpg"),
        require("@/assets/models/Dasha/dasha_31.jpg"),
        require("@/assets/models/Dasha/dasha_32.jpg"),
        require("@/assets/models/Dasha/dasha_33.jpg"),
      ],
    },
    {
        id: 5,
       name: "Katya P",
       height: "174 cm",
       bust: "80 cm",
       waist: "60 cm",
       hips: "90 cm",
       shoes: "39",
       hair: "Light-Brown",
       eyes: "Light-Brown",
       mainPhoto: require("@/assets/models/Katya_P/katya_32.jpg"),
       photos: [
        require("@/assets/models/Katya_P/katya_1.jpg"),
        require("@/assets/models/Katya_P/katya_2.jpg"),
        require("@/assets/models/Katya_P/katya_3.jpg"),
        require("@/assets/models/Katya_P/katya_4.jpg"),
        require("@/assets/models/Katya_P/katya_5.jpg"),
        require("@/assets/models/Katya_P/katya_6.jpg"),
        require("@/assets/models/Katya_P/katya_7.jpg"),
        require("@/assets/models/Katya_P/katya_8.jpg"),
        require("@/assets/models/Katya_P/katya_9.jpg"),
        require("@/assets/models/Katya_P/katya_10.jpg"),
        require("@/assets/models/Katya_P/katya_11.jpg"),
        require("@/assets/models/Katya_P/katya_12.jpg"),
        require("@/assets/models/Katya_P/katya_13.jpg"),
        require("@/assets/models/Katya_P/katya_14.jpg"),
        require("@/assets/models/Katya_P/katya_15.jpg"),
        require("@/assets/models/Katya_P/katya_16.jpg"),
        require("@/assets/models/Katya_P/katya_17.jpg"),
        require("@/assets/models/Katya_P/katya_18.jpg"),
        require("@/assets/models/Katya_P/katya_19.jpg"),
        require("@/assets/models/Katya_P/katya_20.jpg"),
        require("@/assets/models/Katya_P/katya_21.jpg"),
        require("@/assets/models/Katya_P/katya_22.jpg"),
        require("@/assets/models/Katya_P/katya_23.jpg"),
        require("@/assets/models/Katya_P/katya_24.jpg"),
        require("@/assets/models/Katya_P/katya_25.jpg"),
        require("@/assets/models/Katya_P/katya_26.jpg"),
        require("@/assets/models/Katya_P/katya_27.jpg"),
        require("@/assets/models/Katya_P/katya_28.jpg"),
        require("@/assets/models/Katya_P/katya_29.jpg"),
        require("@/assets/models/Katya_P/katya_30.jpg"),
        require("@/assets/models/Katya_P/katya_31.jpg"), 
    ],
  },
  {
    id: 6,
   name: "Lisa",
   height: "169 cm",
   bust: "78 cm",
   waist: "59 cm",
   hips: "88 cm",
   shoes: "38 ",
   hair: "Brown",
   eyes: "Blue",
   mainPhoto: require("@/assets/models/Lisa/lisa_1.jpg"),
   photos: [
    require("@/assets/models/Lisa/lisa_1.jpg"),
    require("@/assets/models/Lisa/lisa_2.jpg"),
    require("@/assets/models/Lisa/lisa_3.jpg"),
    require("@/assets/models/Lisa/lisa_4.jpg"),
    require("@/assets/models/Lisa/lisa_5.jpg"),
    require("@/assets/models/Lisa/lisa_6.jpg"),
    require("@/assets/models/Lisa/lisa_7.jpg"),
    require("@/assets/models/Lisa/lisa_8.jpg"),
    require("@/assets/models/Lisa/lisa_9.jpg"),
    require("@/assets/models/Lisa/lisa_10.jpg"),
    require("@/assets/models/Lisa/lisa_11.jpg"),
    require("@/assets/models/Lisa/lisa_12.jpg"),
    require("@/assets/models/Lisa/lisa_13.jpg"),
    require("@/assets/models/Lisa/lisa_14.jpg"),
    require("@/assets/models/Lisa/lisa_15.jpg"),
    require("@/assets/models/Lisa/lisa_16.jpg"),
    require("@/assets/models/Lisa/lisa_17.jpg"),
    require("@/assets/models/Lisa/lisa_18.jpg"),
    require("@/assets/models/Lisa/lisa_19.jpg"),
    require("@/assets/models/Lisa/lisa_20.jpg"),
    require("@/assets/models/Lisa/lisa_21.jpg"),
    require("@/assets/models/Lisa/lisa_22.jpg"),
    require("@/assets/models/Lisa/lisa_23.jpg"),
    require("@/assets/models/Lisa/lisa_24.jpg"),
    require("@/assets/models/Lisa/lisa_25.jpg"),
    require("@/assets/models/Lisa/lisa_26.jpg"),
    require("@/assets/models/Lisa/lisa_27.jpg"),
    require("@/assets/models/Lisa/lisa_28.jpg"),
    require("@/assets/models/Lisa/lisa_29.jpg"),

],
},
{
    id: 7,
   name: "Nastya",
   height: "182 cm",
   bust: "83 cm",
   waist: "61 cm",
   hips: "91 cm",
   shoes: "41",
   hair: "Light-Brown",
   eyes: "Blue",
   mainPhoto: require("@/assets/models/Nastya/katya_1.jpg"),
   photos: [
    require("@/assets/models/Nastya/katya_1.jpg"),
    require("@/assets/models/Nastya/katya_2.jpg"),
    require("@/assets/models/Nastya/katya_3.jpg"),
    require("@/assets/models/Nastya/katya_4.jpg"),
    require("@/assets/models/Nastya/katya_5.jpg"),
    require("@/assets/models/Nastya/katya_6.jpg"),
    require("@/assets/models/Nastya/katya_7.jpg"),
    require("@/assets/models/Nastya/katya_8.jpg"),
    require("@/assets/models/Nastya/katya_9.jpg"),
    require("@/assets/models/Nastya/katya_10.jpg"),
    require("@/assets/models/Nastya/katya_11.jpg"),
    require("@/assets/models/Nastya/katya_12.jpg"),
    require("@/assets/models/Nastya/katya_13.jpg"),
    require("@/assets/models/Nastya/katya_14.jpg"),
    require("@/assets/models/Nastya/katya_15.jpg"),
    require("@/assets/models/Nastya/katya_16.jpg"),
    require("@/assets/models/Nastya/katya_17.jpg"),
    require("@/assets/models/Nastya/katya_18.jpg"),
    require("@/assets/models/Nastya/katya_19.jpg"),
    require("@/assets/models/Nastya/katya_20.jpg"),
    require("@/assets/models/Nastya/katya_21.jpg"),
],
},
{
    id: 8,
   name: "Sophie",
   height: "181 cm",
   bust: "82 cm",
   waist: "60 cm",
   hips: "90 cm",
   shoes: "40 ",
   hair: "Dark Blonde",
   eyes: "Blue",
   mainPhoto: require("@/assets/models/Sophie/katya_1.jpg"),
   photos: [
    require("@/assets/models/Sophie/katya_1.jpg"),
    require("@/assets/models/Sophie/katya_2.jpg"),
    require("@/assets/models/Sophie/katya_3.jpg"),
    require("@/assets/models/Sophie/katya_4.jpg"),
    require("@/assets/models/Sophie/katya_5.jpg"),
    require("@/assets/models/Sophie/katya_6.jpg"),
    require("@/assets/models/Sophie/katya_7.jpg"),
    require("@/assets/models/Sophie/katya_8.jpg"),
    require("@/assets/models/Sophie/katya_9.jpg"),
    require("@/assets/models/Sophie/katya_10.jpg"),
    require("@/assets/models/Sophie/katya_11.jpg"),
    require("@/assets/models/Sophie/katya_12.jpg"),
    require("@/assets/models/Sophie/katya_13.jpg"),
    require("@/assets/models/Sophie/katya_14.jpg"),
    require("@/assets/models/Sophie/katya_15.jpg"),
    require("@/assets/models/Sophie/katya_16.jpg"),
    require("@/assets/models/Sophie/katya_17.jpg"),
    require("@/assets/models/Sophie/katya_18.jpg"),
    require("@/assets/models/Sophie/katya_19.jpg"),
    require("@/assets/models/Sophie/katya_20.jpg"),
    require("@/assets/models/Sophie/katya_21.jpg"),
    require("@/assets/models/Sophie/katya_22.jpg"),
    require("@/assets/models/Sophie/katya_23.jpg"),
    require("@/assets/models/Sophie/katya_24.jpg"),
    require("@/assets/models/Sophie/katya_25.jpg"),
    require("@/assets/models/Sophie/katya_26.jpg"),
    require("@/assets/models/Sophie/katya_27.jpg"),
    require("@/assets/models/Sophie/katya_28.jpg"),
    require("@/assets/models/Sophie/katya_29.jpg"),
],
},
{
    id: 9,
   name: "Tori",
   height: "177 cm",
   bust: "84 cm",
   waist: "60 cm",
   hips: "90 cm",
   shoes: "38 ",
   hair: "Blonde",
   eyes: "Blue",
   mainPhoto: require("@/assets/models/Tori/katya_23.jpg"),
   photos: [
    require("@/assets/models/Tori/katya_1.jpg"),
require("@/assets/models/Tori/katya_2.jpg"),
require("@/assets/models/Tori/katya_3.jpg"),
require("@/assets/models/Tori/katya_4.jpg"),
require("@/assets/models/Tori/katya_5.jpg"),
require("@/assets/models/Tori/katya_6.jpg"),
require("@/assets/models/Tori/katya_7.jpg"),
require("@/assets/models/Tori/katya_8.jpg"),
require("@/assets/models/Tori/katya_9.jpg"),
require("@/assets/models/Tori/katya_10.jpg"),
require("@/assets/models/Tori/katya_11.jpg"),
require("@/assets/models/Tori/katya_12.jpg"),
require("@/assets/models/Tori/katya_13.jpg"),
require("@/assets/models/Tori/katya_14.jpg"),
require("@/assets/models/Tori/katya_15.jpg"),
require("@/assets/models/Tori/katya_16.jpg"),
require("@/assets/models/Tori/katya_17.jpg"),
require("@/assets/models/Tori/katya_18.jpg"),
require("@/assets/models/Tori/katya_19.jpg"),
require("@/assets/models/Tori/katya_20.jpg"),
require("@/assets/models/Tori/katya_21.jpg"),
require("@/assets/models/Tori/katya_22.jpg"),
 
],
},
{
id: 10,
name: "Ira",
height: "178 cm",
bust: "78 cm",
waist: "60 cm",
hips: "90 cm",
shoes: "40 ",
hair: "Brown",
eyes: "Blue",
mainPhoto: require("@/assets/models/Ira/ira_12.jpg"),
photos: [
 require("@/assets/models/Ira/ira_2.jpg"),
 require("@/assets/models/Ira/ira_3.jpg"),
 require("@/assets/models/Ira/ira_4.jpg"),
 require("@/assets/models/Ira/ira_5.jpg"),
 require("@/assets/models/Ira/ira_6.jpg"),
 require("@/assets/models/Ira/ira_7.jpg"),
 require("@/assets/models/Ira/ira_8.jpg"),
 require("@/assets/models/Ira/ira_9.jpg"),
 require("@/assets/models/Ira/ira_10.jpg"),
 require("@/assets/models/Ira/ira_11.jpg"),

],
},
{
  id: 11,
  name: "Alina",
  height: "169 cm",
  bust: "84 cm",
  waist: "59 cm",
  hips: "86 cm",
  shoes: "38 ",
  hair: "Brown",
  eyes: "Brown",
  mainPhoto: require("@/assets/models/Alina/alina_38.jpg"),
  photos: [
    require("@/assets/models/Alina/alina_1.jpg"),
    require("@/assets/models/Alina/alina_2.jpg"),
    require("@/assets/models/Alina/alina_3.jpg"),
    require("@/assets/models/Alina/alina_4.jpg"),
    require("@/assets/models/Alina/alina_5.jpg"),
    require("@/assets/models/Alina/alina_6.jpg"),
    require("@/assets/models/Alina/alina_7.jpg"),
    require("@/assets/models/Alina/alina_8.jpg"),
    require("@/assets/models/Alina/alina_9.jpg"),
    require("@/assets/models/Alina/alina_10.jpg"),
    require("@/assets/models/Alina/alina_11.jpg"),
    require("@/assets/models/Alina/alina_12.jpg"),
    require("@/assets/models/Alina/alina_13.jpg"),
    require("@/assets/models/Alina/alina_14.jpg"),
    require("@/assets/models/Alina/alina_15.jpg"),
    require("@/assets/models/Alina/alina_16.jpg"),
    require("@/assets/models/Alina/alina_17.jpg"),
    require("@/assets/models/Alina/alina_18.jpg"),
    require("@/assets/models/Alina/alina_19.jpg"),
    require("@/assets/models/Alina/alina_20.jpg"),
    require("@/assets/models/Alina/alina_21.jpg"),
    require("@/assets/models/Alina/alina_22.jpg"),
    require("@/assets/models/Alina/alina_23.jpg"),
    require("@/assets/models/Alina/alina_24.jpg"),
    require("@/assets/models/Alina/alina_25.jpg"),
    require("@/assets/models/Alina/alina_26.jpg"),
    require("@/assets/models/Alina/alina_27.jpg"),
    require("@/assets/models/Alina/alina_28.jpg"),
    require("@/assets/models/Alina/alina_29.jpg"),
    require("@/assets/models/Alina/alina_30.jpg"),
    require("@/assets/models/Alina/alina_31.jpg"),
    require("@/assets/models/Alina/alina_32.jpg"),
    require("@/assets/models/Alina/alina_33.jpg"),
    require("@/assets/models/Alina/alina_34.jpg"),
    require("@/assets/models/Alina/alina_35.jpg"),
    require("@/assets/models/Alina/alina_36.jpg"),
    require("@/assets/models/Alina/alina_37.jpg"),

  ],
  },
  ];