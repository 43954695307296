<template>
  <div class="contact-page">
    <h1 class="contact-title">Contact Us</h1>
    
    <div class="contact-info">
      <div class="office">
        <h2>Moscow</h2>
        <p>RUSSIA</p>
      </div>
    </div>

    <!-- Google Map Embed with Black-and-White Styling -->
    <div class="map-container">
      <iframe
    class="google-map"
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2244.9824541815638!2d37.610321899999995!3d55.758807000000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46b54a4576b772db%3A0xd00437185e8cdbf5!2zR2F6ZXRueXkgUGVyZXVsb2ssIDE3INGB0YLRgNC-0LXQvdC40LUgMiwgTW9za3ZhLCBSdXNzaWEsIDEyNTAwOQ!5e0!3m2!1sen!2str!4v1731301354724!5m2!1sen!2str"
    allowfullscreen=""
    loading="lazy"
  ></iframe>
    </div>

    <div class="footer">
  <div class="owner">
    <h3>Reception</h3>
    <p>Email: <a href="mailto:info@futurefacemodels.com">info@futurefacemodels.com</a></p>

    <h3>New faces development</h3>
    <p>Elizaveta</p>
    <p>Email: <a href="mailto:elizaveta@futurefacemodels.com">elizaveta@futurefacemodels.com</a></p>
  </div>

  <div class="info">
    <h3>Head booker, Agency president</h3>
    <p>Maria Lomonosova</p>
    <p>Email: <a href="mailto:maria@futurefacemodels.com">maria@futurefacemodels.com</a></p>
  </div>
</div>

  </div>
</template>

<script>
export default {
  name: 'ContactPage',
};
</script>

<style scoped>
.contact-page {
  text-align: center;
  padding: 40px;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  font-weight: 300;
}

.contact-title {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  font-weight: 300;
  font-size: 48px;
  letter-spacing: 1px;
  color: #333;
  text-transform: uppercase;
  margin-bottom: 40px;
  text-align: center;
}

.contact-info {
  display: flex;
  justify-content: center;
  gap: 40px;
  margin-bottom: 40px;
}

.office h2 {
  font-weight: 400;
  margin-bottom: 5px;
}

.map-container {
  margin: 20px 0;
}

.google-map {
  width: 100%;
  height: 400px;
  border: 0;
}

.footer {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
}

.footer a {
  color: #333;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}
.map-container iframe {
  filter: grayscale(100%);
  width: 100%;
  height: 400px;
  border: none;
}
@media (max-width: 768px) {
  .footer h3 {
    font-size: 18px; /* Уменьшаем размер заголовков футера на планшетах */
  }

  .footer p {
    font-size: 14px; /* Уменьшаем размер текста на планшетах */
  }

  .contact-title {
    font-size: 36px; /* Опционально: уменьшаем заголовок страницы на планшетах */
    margin-bottom: 30px;
  }

  .contact-info {
    flex-direction: column;
    gap: 20px;
  }

  .google-map {
    height: 300px; /* Уменьшаем высоту карты на планшетах */
  }

  .footer {
    justify-content: center;
  }
}
@media (max-width: 480px) {
  .footer h3 {
    font-size: 16px; /* Уменьшаем размер заголовков футера на мобильных */
  }

  .footer p {
    font-size: 12px; /* Уменьшаем размер текста на мобильных */
  }

  .contact-title {
    font-size: 28px; /* Опционально: уменьшаем заголовок страницы на мобильных */
    margin-bottom: 20px;
  }

  .google-map {
    height: 200px; /* Уменьшаем высоту карты на мобильных */
  }

  .footer {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .owner, .info {
    flex: 1 1 100%;
    margin: 5px 0;
  }
}

</style>
