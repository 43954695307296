export const blogs = [
  {
    id: 16,
    title: "Alina",
    subtitle: "Our new face Alina",
    image: require("@/assets/blog/aln_1.jpg"),
    images: [
      require("@/assets/blog/aln_2.jpg"),
      require("@/assets/blog/aln_3.jpg"),
      require("@/assets/blog/aln_4.jpg"),



    ],
    modelName: "Alina",
    modelId: 11,
    isSpecial: false,
  },
  {
    id: 9,
    title: "Anna",
    subtitle: "for Osoi Japan by CDU ",
    image: require("@/assets/blog/AnnaJP_1.jpg"),
    images: [
      require("@/assets/blog/AnnaJP_2.jpg"),
      require("@/assets/blog/AnnaJP_3.jpg"),
      require("@/assets/blog/AnnaJP_4.jpg"),


    ],
    modelName: "Anna",
    modelId: 3,
    isSpecial: false,
  },
  {
    id: 10,
    title: "Katya's",
    subtitle: "new polaroids by Artem Pomenchuk ",
    image: require("@/assets/blog/kat_1.jpg"),
    images: [
      require("@/assets/blog/kat_2.jpg"),
      require("@/assets/blog/kat_3.jpg"),
      require("@/assets/blog/kat_4.jpg"),
    ],
    modelName: "Katya",
    modelId: 5,
    isSpecial: false,
  },
  {
    id: 11,
    title: "Tori's",
    subtitle: "new polaroids by Artem Pomenchuk ",
    image: require("@/assets/blog/toriP_1.jpg"),
    images: [
      require("@/assets/blog/toriP_2.jpg"),
      require("@/assets/blog/toriP_3.jpg"),
      require("@/assets/blog/toriP_4.jpg"),
      require("@/assets/blog/toriP_5.jpg"),
      require("@/assets/blog/toriP_6.jpg"),

    ],
    modelName: "Tori",
    modelId: 9,
    isSpecial: false,
  },
  {
    id: 12,
    title: "Tori",
    subtitle: "by Elizaveta Mankevich ",
    image: require("@/assets/blog/toriM_1.jpg"),
    images: [
      require("@/assets/blog/toriM_2.jpg"),
      require("@/assets/blog/toriM_3.jpg"),
      require("@/assets/blog/toriM_4.jpg"),
      require("@/assets/blog/toriM_5.jpg"),
      require("@/assets/blog/toriM_6.jpg"),
    ],
    modelName: "Tori",
    modelId: 9,
    isSpecial: false,
  },
{
    id: 13,
    title: "Lisa",
    subtitle: "for Guse Brand by Ever models",
    image: require("@/assets/blog/lisa1.jpg"),
    images: [
      require("@/assets/blog/lisa1.jpg"),
      require("@/assets/blog/lisa2.jpg"),


    ],
    modelName: "Lisa",
    modelId: 6,
    isSpecial: false,
  },
  {
    id: 14,
    title: "Anna",
    subtitle: "for Yuthentic campaign",
    image: require("@/assets/blog/annaYC_1.jpg"),
    images: [
      require("@/assets/blog/annaYC_1.jpg"),
      require("@/assets/blog/annaYC_2.jpg"),



    ],
    modelName: "Lisa",
    modelId: 6,
    isSpecial: false,
  },
  {
    id: 15,
    title: "Anastasia's",
    subtitle: "test by Aigul Akhmadullina",
    image: require("@/assets/blog/anst_1.jpg"),
    images: [
      require("@/assets/blog/anst_2.jpg"),
      require("@/assets/blog/anst_3.jpg"),
      require("@/assets/blog/anst_4.jpg"),
      require("@/assets/blog/anst_5.jpg"),



    ],
    modelName: "Anastasia",
    modelId: 7,
    isSpecial: false,
  },
  {
    id: 17,
    title: "Tori",
    subtitle: "by Dima Ryazanov",
    image: require("@/assets/blog/vk_1.jpg"),
    images: [
      require("@/assets/blog/vk_9.jpg"),
      require("@/assets/blog/vk_2.jpg"),
      require("@/assets/blog/vk_3.jpg"),
      require("@/assets/blog/vk_4.jpg"),
      require("@/assets/blog/vk_5.jpg"),
      require("@/assets/blog/vk_6.jpg"),
      require("@/assets/blog/vk_7.jpg"),
      require("@/assets/blog/vk_8.jpg"),
      require("@/assets/blog/vk_10.jpg"),
    ],
    modelName: "Tori",
    modelId: 13,
    isSpecial: false,
  },
  {
    id: 18,
    title: "Lisa",
    subtitle: "for Waviness campaign by Ever models",
    image: require("@/assets/blog/ls_1.jpg"),
    images: [
      require("@/assets/blog/ls_2.jpg"),
      require("@/assets/blog/ls_3.jpg"),
      require("@/assets/blog/ls_4.jpg"),
      
    ],
    modelName: "Lisa",
    modelId: 6,
    isSpecial: false,
  },
  {
    id: 19,
    title: "Alexandra",
    subtitle: "for White campaign by GFI and Only",
    image: require("@/assets/blog/alex_1.jpg"),
    images: [
      require("@/assets/blog/alex_2.jpg"),
      require("@/assets/blog/alex_3.jpg"),
      require("@/assets/blog/alex_4.jpg"),
      
    ],
    modelName: "Alexandra",
    modelId: 1,
    isSpecial: false,
  },
  {
    id: 20,
    title: "Anna",
    subtitle: "for Gloria Jeans campaign",
    image: require("@/assets/blog/gloriajeans1.jpg"),
    images: [
      require("@/assets/blog/gloriajeans1.jpg"),
      require("@/assets/blog/gloriajeans2.jpg"),
      require("@/assets/blog/gloriajeans3.jpg"),
    ],
    modelName: "Anna Grishina",
    modelId: 3,
    isSpecial: false, // Обычная карточка
  },
  {
    id: 21,
    title: "Alexandra",
    subtitle: "for Numéro Russia editorial",
    image: require("@/assets/blog/numero_1.jpg"),
    images: [
      require("@/assets/blog/numero_1.jpg"),
      require("@/assets/blog/numero_2.jpg"),
      require("@/assets/blog/numero_3.jpg"),
    ],
    modelName: "Alexandra & Anastasia",
    modelId: 1,
    isSpecial: true, // Карточка с соотношением сторон 16:9
  },
  {
    id: 22,
    title: "Dasha Grim",
    subtitle: "for Moussy look book by Wizard models",
    image: require("@/assets/blog/mousy1.jpg"),
    images: [
      require("@/assets/blog/mousy1.jpg"),
      require("@/assets/blog/mousy2.jpg"),
      require("@/assets/blog/mousy3.jpg"),
      require("@/assets/blog/mousy4.jpg"),
      require("@/assets/blog/mousy5.jpg"),
    ],
    modelName: "Dasha Grim",
    modelId: 4,
    isSpecial: false,
  },
  {
    id: 23,
    title: "Katya P.",
    subtitle: "for ZNY look book",
    image: require("@/assets/blog/zny1.jpg"),
    images: [
      require("@/assets/blog/zny1.jpg"),
      require("@/assets/blog/zny2.jpg"),
      require("@/assets/blog/zny3.jpg"),
    ],
    modelName: "Katya Ponasenko",
    modelId: 5,
    isSpecial: false, // Карточка с соотношением сторон 16:9
  },
  {
    id: 24,
    title: "Anna",
    subtitle: "for ТВОЕ campaign",
    image: require("@/assets/blog/TBOE&NoblesseKorea5.jpg"),
    images: [
      require("@/assets/blog/TBOE&NoblesseKorea5.jpg"),
      require("@/assets/blog/TBOE&NoblesseKorea6.jpg"),
    ],
    modelName: "Anna Grishina",
    modelId: 3,
    isSpecial: false,
  },
  {
    id: 25,
    title: "Julia",
    subtitle: "by Nate Mazin",
    image: require("@/assets/blog/nate_1.jpg"),
    images: [
      require("@/assets/blog/nate_1.jpg"),
      require("@/assets/blog/nate_2.jpg"),
      require("@/assets/blog/nate_3.jpg"),
      require("@/assets/blog/nate_4.jpg"),
      require("@/assets/blog/nate_5.jpg"),
      require("@/assets/blog/nate_6.jpg"),
      require("@/assets/blog/nate_7.jpg"),
      require("@/assets/blog/nate_8.jpg"),
      require("@/assets/blog/nate_9.jpg"),
    ],
    modelName: "Julia",
    modelId: 2,
    isSpecial: false, // Карточка с соотношением сторон 16:9
  },
  {
    id: 26,
    title: "Dasha Grim",
    subtitle: "by Igor Pavlov",
    image: require("@/assets/blog/pavlov_3.jpg"),
    images: [
      require("@/assets/blog/pavlov_1.jpg"),
      require("@/assets/blog/pavlov_2.jpg"),
      require("@/assets/blog/pavlov_3.jpg"),
      require("@/assets/blog/pavlov_4.jpg"),
    ],
    modelName: "Dasha Grim",
    modelId: 4,
    isSpecial: false,
  },
  {
    id: 27,
    title: "Anna",
    subtitle: "for Noblesse South Korea editorial ",
    image: require("@/assets/blog/TBOE&NoblesseKorea1.jpg"),
    images: [
      require("@/assets/blog/TBOE&NoblesseKorea1.jpg"),
      require("@/assets/blog/TBOE&NoblesseKorea2.jpg"),
      require("@/assets/blog/TBOE&NoblesseKorea3.jpg"),
      require("@/assets/blog/TBOE&NoblesseKorea4.jpg"),
    ],
    modelName: "Anna Grishina",
    modelId: 3,
    isSpecial: false,
  },
];
